import { createTheme } from '@mui/material/styles';
import { frFR } from '@mui/x-data-grid';

const theme = createTheme({
  // frFR,
  // palette: {
  //   type: 'light',
  //   primary: {
  //     main: 'rgb(250,182,21)',
  //   },
  //   secondary: {
  //     main: '#000000',
  //   },
  //   background: {
  //     default:'#333',
  //   },
  // },
  // typography: {
  //   fontFamily:'"Roboto",  sans-serif',
  //   h1: {
  //     fontSize: 24,
  //     fontWeight: 400,
  //   },
  //   h2: {
  //     fontSize: 20,
  //     fontWeight: 400,
  //   },
  //   h3: {
  //     fontSize: 20,
  //     fontWeight: 500,
  //   },
  //   h4: {
  //     fontSize: 16,
  //     fontWeight: 400,
  //   },
  //   h5: {
  //     fontSize: 14,
  //     fontWeight: 400,
  //   },
  //   h6: {
  //     fontSize: 20,
  //     fontWeight: 700,
  //   },
  //   body1: {
  //     fontSize: 16,
  //     fontWeight: 400,
  //   },
  //   subtitle1: {
  //     fontSize: 20,
  //     fontWeight: 700,
  //   },
  //   subtitle2: {
  //     fontSize: 20,
  //     fontWeight: 400,
  //   },
  // },

});

export default theme
