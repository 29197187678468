import config from 'config'
import { useEffect } from 'react'
import { deleteAllCookies } from 'utils/cookie'
import { restIO } from 'utils/io'
import { removeServiceWorker } from 'utils/serviceWorker'

const NotLogged = () => {
    useEffect(() => {
        let stopRedirect = window.localStorage.getItem('stopRedirect') || false
        if (!!stopRedirect === true) debugger
        removeServiceWorker()
        deleteAllCookies() 

        // deloguer le coté serveur (jwt blacklist, bdd, cookie etc)
        const logout = async () => {
            const oauthLogoutUrl = config.oauth.logout.url
            try { await restIO({ endpoint: oauthLogoutUrl, jwt: true }) } catch (error) { console.log(error) }
        }
        logout()
        // recuperation de la redirection qui provient du referer avant de supprimer le localStorage
        let from = window.localStorage.getItem('redirectToReferrer')
        window.sessionStorage.clear()
        window.localStorage.clear()
        // repasse le redirection qui provient du referer dans le storage
        if (from) window.localStorage.setItem('redirectToReferrer', from)
        window.location.href = config.front.home.url // redirection vers la home
    }, [])
    return null
}
export default NotLogged
