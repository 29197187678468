// import * as MuiIcons from '@mui/icons-material';

// console.log(MuiIcons); // Ajoutez cette ligne pour voir toutes les icônes disponibles

// const useDynamicIcon = (iconName) => {
//     const Icon = MuiIcons[iconName]
//     return Icon
// }
// const UseDynamicIcon = (props) => {
//     let { iconName, ...rest } = props
//     const Icon = useDynamicIcon(iconName)
//     return <Icon {...rest} />
// }

// export { useDynamicIcon };
// export default UseDynamicIcon


import * as MuiIcons from '@mui/icons-material'

// console.log(MuiIcons); // Ajoutez cette ligne pour voir toutes les icônes disponibles

const useDynamicIcon = (iconName) => {
    const Icon = MuiIcons[iconName]
    return Icon ? Icon : null
}

const UseDynamicIcon = (props) => {
    let { iconName, ...rest } = props
    const Icon = useDynamicIcon(iconName)

    if (!Icon) {
        console.error(`Icon with name ${iconName} does not exist in @mui/icons-material.`)
        return null
    }

    return <Icon {...rest} />
}

export { useDynamicIcon }
export default UseDynamicIcon
