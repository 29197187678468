import { Tooltip } from '@mui/material';
import { isValidElement } from 'react';

const KTooltip = (props) => {
  const { noLogo = false, title, arrow = true, children } = props;

  // Check if the child is a disabled element
  const isDisabled = children.props.disabled;

  // If the child is a disabled element, wrap it in a div
  const childElement = isDisabled ? (
    <span style={{ display: 'inline-block', cursor: 'not-allowed' }}>
      {children}
    </span>
  ) : (
    children
  );

  return (
    <KTooltip arrow={arrow} title={title} {...props}>
      {childElement}
    </KTooltip>
  );
};

export { KTooltip };
