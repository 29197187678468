import { Button, CardActions, CardContent, Divider, Grid, InputAdornment, TextField,Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useSnackbar } from "notistack"
import { restIO } from "utils/io"
import { Link, useParams } from "react-router-dom"
import config from "config"
import { deleteAllCookies } from "utils/cookie"
import { removeServiceWorker } from "utils/serviceWorker"
import { LockRounded, VisibilityRounded } from "@mui/icons-material"
import { makeStyles } from "tss-react/mui"
import useDebounce from "../../utils/debounce";

const useStyles = makeStyles()((theme) => ({
    card: {
        border: "0",
        marginBottom: "30px",
        marginTop: "30px",
        borderRadius: "6px",
        color: "rgba(0, 0, 0, 0.87)",
        background: "#fff",
        width: "100%",
        boxShadow:
            "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        minWidth: "0",
        wordWrap: "break-word",
        fontSize: ".875rem",
        transition: "all 300ms linear",
    },
    cardHeader: {
        borderRadius: "3px",
        // padding: "1rem 15px",
        // marginLeft: "15px",
        // marginRight: "15px",
        // marginTop: "-30px",
        // border: "0",
        // marginBottom: "0",
        color: "#fff",
        background: "linear-gradient(60deg, rgb(250,182,21), rgb(250,182,21))",
        boxShadow:
            "0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)",

        width: "auto",
        textAlign: "center",
        marginLeft: "20px",
        marginRight: "20px",
        marginTop: "-40px",
        padding: "20px 0",
        marginBottom: "15px"
    },
    divider: {
        marginTop: "30px",
        marginBottom: "0px",
        textAlign: "center"
    },
    cardActions: {
        paddingTop: "0rem",
        border: "0",
        borderRadius: "6px",
        justifyContent: "center !important"
    },
}))

const ChangePasswordPage = (props) => {
    const { classes } = useStyles()
    let { type = "reset" } = props


    const { ...params } = useParams()

    const { enqueueSnackbar } = useSnackbar()
    const [newUserPassword, setNewUserPassword] = useState('')
    const [newUserPassword2, setNewUserPassword2] = useState('')
    const [flagPasswordVisible, setFlagPasswordVisible] = useState(false) // etat lié à l'affichage en clair du mdp
    const [flagPasswordVisible2, setFlagPasswordVisible2] = useState(false) // etat lié à l'affichage en clair du mdp
    const [flagNewUserPassword, setFlagNewUserPassword] = useState(false) // etat lié à l'affichage en clair du mdp
    const [flagNewUserPassword2, setFlagNewUserPassword2] = useState(false) // etat lié à l'affichage en clair du mdp
    const debouncedNewUserPassword = useDebounce(newUserPassword, 2000); // retarde de 2 secondes l'affichage si erreur
    const debouncedNewUserPassword2 = useDebounce(newUserPassword2, 1000); // retarde de 1 seconde l'affichage si erreur
    useEffect(() => {
        if (!debouncedNewUserPassword.match(config.securityPolicy.patternUserPassword )) {
            setFlagNewUserPassword(true)
        } else
            setFlagNewUserPassword(false)

        if (debouncedNewUserPassword !== debouncedNewUserPassword2) {
            setFlagNewUserPassword2(true)

        }
        else setFlagNewUserPassword2(false)

    }, [debouncedNewUserPassword, debouncedNewUserPassword2])

    const handleSubmitPassword = async () => {
        if (!!flagNewUserPassword === true || !!flagNewUserPassword2 === true) return

        let pfChangePasswordUrl = config.pf.public.user.changePassword.url
        let responseKeepapi = await restIO({ method: 'post', endpoint: pfChangePasswordUrl, payload: { token: params.token, newUserPassword } }) // fecth oauth login url
        let { datas, extraData, details, code, error, message, status } = responseKeepapi
        if (code !== "ok") {
            if (status === 400) {
                message = "Le mot de passe choisi ne suit pas la politique de sécurité de mot de passe de Plurélya. (202208301544)"
                enqueueSnackbar(message, { variant: "warning" });
            } else
                enqueueSnackbar(message, { variant: "warning" });
            return;
        }
        // nous recuperons le userLogin dans les extradata pour lancer l'autolog
        let userLogin = extraData.userLogin
        message = `La modification de votre mot de passe a été pris en compte. Le systême va maintenant vous connecter à l'extranet Plurélya.`;
        enqueueSnackbar(message, { variant: "success" });
        handleLogIn(userLogin, newUserPassword)
    }
    const handleLogIn = async (userLogin, userPassword) => {
        removeServiceWorker() // remove service worker cache
        deleteAllCookies([config.app.kmSessionName]) // remove cookies
        window.localStorage.removeItem('JWT') // remove JWT
        
        let oauthLoginUrl = config.oauth.login.url
        let responseKeepapi = await restIO({ method: 'post', endpoint: oauthLoginUrl, payload: { userLogin, userPassword } }) // fecth oauth login url
        // at this time cookie are stored in browser
        let { datas, extraData, details, code, error } = responseKeepapi
        let loggedIn = false
        if (code == 'ok') {
            let data = datas[0]
            if (data.auth === true && data.token !== '') { // you are logged !!!
                window.localStorage.setItem('JWT', data.token)
                window.localStorage.setItem('refresh', data.refresh)
                loggedIn = true
            }
        }
        if (loggedIn === true) {
            // prepare autolog url for admin
            let autologUrl = config.admin.autolog.url
            autologUrl = autologUrl.replace('___TOKEN___', window.localStorage.getItem('JWT'))
            window.location.href = autologUrl // let's go to autolog at admin
        }
        else {
            let message = "Nous ne parvenons pas à vous connecter à la plateforme"
            enqueueSnackbar(message, { variant: 'warning' })
        }
    }
    return (
        <div className={classes.card}>
            <div color="primary"
                className={classes.cardHeader}
            >
                <h4>
                    Formulaire de {type === "reset" ? 'réinitialisation' : "création"} de votre mot de passe
                </h4>
            </div>
            <CardContent sx={{ maxWidth: 550 }}>


                <Typography variant="body2">
                    A noter: Le lien de {type === "reset" ? 'réinitialisation' : "création"} de votre mot de passe expire dans une heure.
                    <br />
                    Si le lien ne fonctionne plus, vous pouvez en re-demander <Link to="/login">un autre ici</Link>
                </Typography>
                <form onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                        ev.preventDefault()
                        handleSubmitPassword()
                    }
                }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField margin="normal" fullWidth autoFocus={true}
                                InputProps={{
                                    type: !!flagPasswordVisible === true ? 'text' : 'password',
                                    endAdornment: (<InputAdornment position="end" onClick={() => setFlagPasswordVisible(!flagPasswordVisible)}>{!!flagPasswordVisible === true ? <LockRounded /> : <VisibilityRounded />}</InputAdornment>)
                                }}
                                label="Mot de passe"
                                value={newUserPassword}
                                onChange={(ev) => setNewUserPassword(ev.target.value)}
                                error={debouncedNewUserPassword !== '' && !!flagNewUserPassword === true}
                                helperText={debouncedNewUserPassword !== '' && !!flagNewUserPassword === true && config.securityPolicy.patternUserPasswordText}
                            ></TextField>
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <TextField margin="normal" fullWidth autoFocus={false}
                            InputProps={{
                                type: !!flagPasswordVisible2 === true ? 'text' : 'password',
                                endAdornment: (<InputAdornment position="end" onClick={() => setFlagPasswordVisible2(!flagPasswordVisible2)}> {!!flagPasswordVisible2 === true ? <LockRounded /> : <VisibilityRounded />} </InputAdornment>)
                            }}
                            label="Confirmez votre mot de passe"
                            value={newUserPassword2}
                            onChange={(ev) => setNewUserPassword2(ev.target.value)}
                            error={debouncedNewUserPassword2 !== '' && !!flagNewUserPassword2 === true}
                            helperText={debouncedNewUserPassword2 !== '' && !!flagNewUserPassword2 === true && config.securityPolicy.patternUserConfirmPasswordText}
                        ></TextField>
                    </Grid>
                </form>

            </CardContent>
            <CardActions disableSpacing className={classes.cardActions}>
                <Button color="primary" variant="contained" onClick={handleSubmitPassword}
                        disabled={debouncedNewUserPassword2 === ''|| !!flagNewUserPassword2 === true || debouncedNewUserPassword2.includes(" ")} >
                    {type === "reset" ? 'RÉINITIALISER' : "CRÉER"} VOTRE MOT DE PASSE</Button>
            </CardActions>
        </div>
    )
}


export default ChangePasswordPage
