import nirGenerator from 'nir-generator';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import config from "config"
import { Button, CardActions, CardContent, Divider, Grid, InputAdornment, Stack, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { removeServiceWorker } from "utils/serviceWorker"
import { deleteAllCookies } from "utils/cookie"
import { useSnackbar } from "notistack"
import { restIO } from "utils/io"
import { makeStyles } from "tss-react/mui"
import { AutoFixHighRounded, Diversity2Rounded, HomeRounded, LockRounded, MailRounded, PersonRounded, PhoneRounded, VisibilityRounded, VpnKeyRounded } from "@mui/icons-material"
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@emotion/react';



const useStyles = makeStyles()((theme) => ({
    card: {
        border: "0",
        marginBottom: "30px",
        marginTop: "30px",
        borderRadius: "6px",
        color: "rgba(0, 0, 0, 0.87)",
        background: "#fff",
        width: "500px",
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
        boxShadow:
            "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        minWidth: "0",
        wordWrap: "break-word",
        fontSize: ".875rem",
        transition: "all 300ms linear"
    },
    cardHeader: {
        borderRadius: "3px",
        color: "#fff",
        background: `linear-gradient(60deg, ${theme.palette.primary.main}, ${theme.palette.primary.main})`,
        boxShadow:
            "0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)",

        width: "auto",
        textAlign: "center",
        marginLeft: "20px",
        marginRight: "20px",
        marginTop: "-40px",
        padding: "20px 0",
        marginBottom: "15px"
    },
    divider: {
        marginTop: "30px",
        marginBottom: "0px",
        textAlign: "center"
    },
    cardActions: {
        paddingTop: "0rem",
        border: "0",
        borderRadius: "6px",
        margin: "10px",
        justifyContent: "center !important"
    },
}))

/**
 * viewModeStep
 * connectStep1 =  identifiant
 * connectStep2 = mdp
 *    forgotUserLogin = identifiant oublié
 * forgotUserLoginAgent = identifiant oublié en mode agent
* forgotUserPasswordRecoverByEmail = mot de passe oublié recover by email
*  forgotUserPasswordRecoverBySMS = mot de passe oublié recover by SMS
*  forgotUserPasswordRecoverByAddress = mot de passe oublié recover by paper

* forgotUserPasswordNoSMSNoEmailNoAddressRecover = ni sms ni email associé au compte
 *  */

const LoginPage = (props) => {
    const { classes } = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const [userLogin, setUserLogin] = useState('')
    const [agentLastName, setAgentLastName] = useState('')
    const [agentFirstName, setAgentFirstName] = useState('')
    const [addressPostcode, setAddressPostcode] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [addressMobile, setAddressMobile] = useState('')
    const [flagErrorUserEmail, setFlagErrorUserEmail] = useState(true)
    const [flagErrorAddressMobile, setFlagErrorAddressMobile] = useState(true)
    const [flagErrorAddress, setFlagErrorAddress] = useState(true)

    useEffect(() => {
        if (userEmail !== '') {
            setFlagErrorUserEmail(!isEmail(userEmail))
        }
    }, [userEmail])

    useEffect(() => {
        if (addressMobile !== '') {
            setFlagErrorAddressMobile(!isMobilePhone(addressMobile, ["fr-BE", "fr-FR"]))
        }
    }, [addressMobile])

    const [viewModeStep, setViewModeStep] = useState(!!props.match.params.keepALinkToken ? "connectStep3" : "connectStep1")
    const [flagPasswordVisible, setFlagPasswordVisible] = useState(false) // etat lié à l'affichage en clair du mdp
    const [userId, setUserId] = useState()
    const [userDeleteDatetime, setUserDeleteDatetime] = useState("")
    const [flagHasEmail, setFlagHasEmail] = useState(false)
    const [flagHasUniqEmail, setFlagHasUniqEmail] = useState(false)
    const [flagHasSMS, setFlagHasSMS] = useState(false)
    const [flagHasAddress, setFlagHasAddress] = useState(false)
    const [flagHasUniqSMS, setFlagHasUniqSMS] = useState(false)
    const [emailProviderWithAvailableSubscription, setEmailProviderWithAvailableSubscription] = useState("")
    const [emailProviderAlreadySubscribe, setEmailProviderAlreadySubscribe] = useState("")
    const [flagCanMagickLink, setFlagCanMagickLink] = useState(false)


    const [userPassword, setUserPassword] = useState('')

    const [loader, setLoader] = useState(false)
    const handleMagickLink = async (event) => {
        setLoader(true)
        if (event) event.preventDefault()
        let apiMagickLinkCommunicationUrl = config.api.public.login.magickLinkCommunication.url
        let responseKeepapi = await restIO({ method: 'post', endpoint: apiMagickLinkCommunicationUrl, payload: { userId } }) // fecth /public/login/apiMagickLinkCommunicationUrl
        let { datas, extraData, details, code, error, message = "", showBackendMessage = false } = responseKeepapi
        setLoader(false)
        if (code !== 'ok') {
            if (showBackendMessage === true)
                enqueueSnackbar(message, { variant: 'warning' })
            else {
                message = "Le lien à usage unique n'a pas pu vous être envoyé."
                enqueueSnackbar(message, { variant: 'warning' })
            }
            return
        }
        message = "Le lien à usage unique a été envoyé sur votre boite mail."
        enqueueSnackbar(message, { variant: 'success' })
        setViewModeStep("connectStep1")
        return
    }

    const handleUserLogin = async (event) => {


        if (event) event.preventDefault()
        if (userLogin.trim() === "") {
            let message = "L'identifiant est vide"
            enqueueSnackbar(message, { variant: 'warning' })
            return
        }
        // let apiGetTransactionalRouteUrl = config.api.public.login.getTransactionalRouteUrl.url
        // let responseKeepapi = await restIO({ method: 'post', endpoint: apiGetTransactionalRouteUrl, payload: { userLogin } }) // fecth /public/login/getTransactionalRoute
        // let { datas, extraData, details, code, error, message, showBackendMessage = false } = responseKeepapi
        // if (code !== 'ok') {
        //     if (showBackendMessage === true)
        //         enqueueSnackbar(message, { variant: 'warning' })
        //     else {
        //         message = "L'identifiant n'est pas reconnu"
        //         enqueueSnackbar(message, { variant: 'warning' })
        //     }
        //     return
        // }

        // // a partir d'ici nous devrions avoir dans la réponse api les extradata qui permette de choisir le bon deroulement du formulaire de connexion
        // let {
        //     userId,
        //     flagHasEmail,
        //     flagHasUniqEmail,
        //     flagHasSMS,
        //     flagHasAddress,
        //     flagHasUniqSMS,
        //     emailProviderWithAvailableSubscription,
        //     emailProviderAlreadySubscribe,
        //     flagCanMagickLink,
        //     userDeleteDatetime
        // } = extraData
        // setUserId(userId)
        // setFlagHasEmail(flagHasEmail)
        // setFlagHasUniqEmail(flagHasUniqEmail)
        // setFlagHasSMS(flagHasSMS)
        // setFlagHasAddress(flagHasAddress)
        // setFlagHasUniqSMS(flagHasUniqSMS)
        // setEmailProviderWithAvailableSubscription(emailProviderWithAvailableSubscription)
        // setEmailProviderAlreadySubscribe(emailProviderAlreadySubscribe)
        // setFlagCanMagickLink(flagCanMagickLink)
        // setUserDeleteDatetime(userDeleteDatetime)
        setViewModeStep("connectStep2")



    }
    const handleLogInWithGoogle = async (response) => {
        const oauthLoginUrl = config.oauth.loginWithGoogle.url
        return handleLogInWithSocialNetwork(response, oauthLoginUrl)
    }
    const handleSubscribeWithProvider = async (providerResponse) => {
        // if (!providerResponse) {
        //     let message = `${providerResponse.externalProvider} envoi un identifiant qui n'est pas reconnu dans notre base. Nous ne retrouvons pas votre fiche. Vous pouvez réessayer en vous reconnectant de ${providerResponse.externalProvider}. Si le problème persiste utilisez un autre réseau social accessible ou entrez votre email et votre mot de passe dans le formulaire`
        //     let autoHideDuration = 25000

        //     let variant = 'warning'
        //     this.props.enqueueSnackbar(message, { variant, autoHideDuration })
        //     return
        // }

        // if (providerResponse.email === '' || !providerResponse.email.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$/i)) {
        //     let message = `Votre compte ${providerResponse.externalProvider} a bien été détécté, néanmoins il semble qu'il n'y ait pas d'email rattaché à ce compte. Pour communiquer avec vous, l'université aura besoin d'un email valide. Vous pouvez soit complèter votre email chez ${providerResponse.externalProvider}. Soit utiliser un autre bouton réseau social. Soit saisir vous même le champ email et mot de passe sur le formulaire de demande.`
        //     let autoHideDuration = 25000
        //     let variant = 'warning'
        //     this.props.enqueueSnackbar(message, { variant, autoHideDuration })
        //     return
        // }
        // if (providerResponse.externalProvider === 'microsoft')
        //     this.setState({
        //         userMicrosoftExternalId: providerResponse.externalId,
        //     })
        // else if (providerResponse.externalProvider === 'google') {
        //     this.setState({
        //         userGoogleExternalId: providerResponse.externalId,
        //     })
        // }
        // this.setState({
        //     externalProvider: providerResponse.externalProvider,
        //     socialNetworkSubscription: true,
        //     userEmail: providerResponse.email,
        //     userProfileImageUrl: providerResponse.profile.imageUrl,
        // })
        // if (this.state.userLastName === '' && providerResponse.profile.lastName !== '') {
        //     this.setState({
        //         userLastName: providerResponse.profile.lastName
        //     })
        // }
        // if (this.state.userFirstName === '' && providerResponse.profile.firstName !== '') {
        //     this.setState({
        //         userFirstName: providerResponse.profile.firstName
        //     })
        // }
        // let message = `L'identification a réussi. Verifiez bien l'email que ${providerResponse.externalProvider} nous a transmis : ${providerResponse.email}.`
        // let variant = 'success'
        // this.props.enqueueSnackbar(message, { variant })
    }
    const handleLogInWithSocialNetwork = async (response, oauthLoginUrl) => {
        // const components = lodash.extend(this.state.components)
        // components.KMMaterialWaiting.displayWaiting = true
        // this.setState({ components: components })
        // let error = true
        // error = error & response.email !== '' & !!response.email.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$/i)
        // error = error & response.externalId !== ''
        // if (!!error !== false) {
        //   function removeCookie(name, path, domain) {
        //     let str = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;${path ? " path=" + path + ";" : ""}${domain ? " domain=" + domain + ";" : ""}`
        //     document.cookie = str
        //   }
        //   removeCookie("kmSession", '/', `.${config.mainDomainName}`)
        //   window.localStorage.removeItem('JWT')
        //   let loggedIn = false
        //   let messageDetail = ''
        //   try {
        //     let env = await fetch(oauthLoginUrl, {
        //       headers: {
        //         'Content-Type': 'application/json',
        //         'Accept': 'application/json'
        //       },
        //       credentials: 'include',
        //       method: 'POST',
        //       body: JSON.stringify({ email: response.email, password: response.externalId })
        //     })
        //     let { code, data } = await env.json()
        //     if (code === 'ok') {
        //       if (data.auth === true && data.token !== '') {
        //         window.localStorage.setItem('JWT', data.token)
        //         loggedIn = true
        //       }
        //     } else {
        //       if (data && data.message && Array.isArray(data.message) && data.message.length > 0 && data.message[0].startsWith("Votre compte"))
        //         messageDetail = data.message[0]
        //     }
        //   } catch (error) {
        //     loggedIn = false
        //   }
        //   if (loggedIn === true) {
        //     let apirUrl = config.admin.autolog.url
        //     apirUrl = apirUrl.replace('___TOKEN___', window.localStorage.getItem('JWT'))
        //     window.location.href = apirUrl
        //   } else {
        //     let message = this.props.langStore.loginForm.error.oauth.not_found
        //     if (messageDetail !== '') message = messageDetail
        //     let variant = 'info'
        //     this.props.enqueueSnackbar(message, { variant })
        //   }
        // } else {
        //   let message = this.props.langStore.loginForm.error.oauth.not_found
        //   let variant = 'info'
        //   this.props.enqueueSnackbar(message, { variant })
        // }
        // components.KMMaterialWaiting.displayWaiting = false
        // this.setState({ components: components })
    }
    useEffect(async () => {
        if (!!props.match.params.keepALinkToken && !!props.match.params.refPId) {

            let stopRedirect = window.localStorage.getItem('stopRedirect') || false
            if (!!stopRedirect === true) debugger
            // need to tick one time on a random oauth url to set _csrf_cookie see next call
            let oauthGetCSRFBeforeOauth = config.oauth.probe.url
            let responseKeepapiFake = await restIO({ method: 'get', endpoint: oauthGetCSRFBeforeOauth })

            let oauthFromClientSite = config.oauth.logFromClient.url
            let responseKeepapi = await restIO({
                method: 'post', endpoint: oauthFromClientSite, payload: {
                    keepALinkToken: props.match.params.keepALinkToken,
                    refPId: props.match.params.refPId
                }
            })
            let { datas, extraData, details, code, error, message = "", showBackendMessage = false } = responseKeepapi
            let loggedIn = false
            if (code === 'ok') {
                let data = datas[0]
                if (data.auth === true && data.token !== '') { // you are logged !!!
                    window.localStorage.setItem('JWT', data.token)
                    window.localStorage.setItem('refresh', data.refresh)
                    loggedIn = true
                }
            }
            if (loggedIn === true) {
                const urlParams = new URLSearchParams(window.location.search);
                const redirectToReferrer = urlParams.get('redirectToReferrer');
                
                // prepare autolog url for admin
                let autologUrl = config.admin.autolog.url
                autologUrl = autologUrl.replace('___TOKEN___', window.localStorage.getItem('JWT'))
                if (redirectToReferrer)
                    autologUrl+="?redirectToReferrer=" + redirectToReferrer
                window.location.href = autologUrl // let's go to autolog at admin
            }
            else if (userDeleteDatetime !== "" && datas.message === '') {
                let message = `Vous avez été sorti des utilisateurs. Vous ne pouvez plus vous connecter à KeepALink.`
                enqueueSnackbar(message, { variant: 'info' })
            }
            else {
                let message = datas.message
                enqueueSnackbar(message, { variant: 'warning' })
            }
            setLoader(false)
        }
    }, [])

    const handleForgotUserPassword = async (event) => {
        if (!!flagHasEmail === true) {
            setViewModeStep("forgotUserPasswordRecoverByEmail")
        } else if (!!flagHasSMS === true) {
            setViewModeStep("forgotUserPasswordRecoverBySMS")
        } else if (!!flagHasAddress === true) {
            setViewModeStep("forgotUserPasswordRecoverByAddress")
        } else
            setViewModeStep("forgotUserPasswordNoSMSNoEmailNoAddressRecover")
    }
    const handleForgotUserPasswordRecoverByEmail = async (event) => {
        setLoader(true)
        let pfUrl = config.pf.public.url + '/pf/public/user/userForgotPasswordByEmail';
        let payload = { userEmail, userLogin }
        let responseKeepapi = await restIO({ endpoint: pfUrl, method: "post", jwt: false, payload });
        let { datas, extraData, details, code, status, error, showBackendMessage = false, message: backendMessage } = responseKeepapi
        status = parseInt(status)
        if (showBackendMessage === true) enqueueSnackbar(backendMessage, { variant: 'warning', autoHideDuration: 10000 })
        else if (code !== 'ok') enqueueSnackbar("Erreur lors de la recherche de votre email", { variant: 'warning', autoHideDuration: 10000 })
        else {
            enqueueSnackbar("Un lien pour réinitialiser votre mot de passe vient de vous être envoyé sur votre adresse mail " + userEmail, { variant: 'success', autoHideDuration: 10000 })
            setViewModeStep('connectStep1')
        }
        setLoader(false)
    }
    const handleForgotUserPasswordRecoverBySMS = async (event) => {
        setLoader(true)
        let pfUrl = config.pf.public.url + '/pf/public/user/userForgotPasswordBySMS';
        let payload = { addressMobile, userLogin }
        let responseKeepapi = await restIO({ endpoint: pfUrl, method: "post", jwt: false, payload });
        let { datas, extraData, details, code, status, error, showBackendMessage = false, message: backendMessage } = responseKeepapi
        status = parseInt(status)
        if (showBackendMessage === true) enqueueSnackbar(backendMessage, { variant: 'warning', autoHideDuration: 10000 })
        else if (code !== 'ok') enqueueSnackbar("Erreur lors de la recherche de votre numéro de téléphone", { variant: 'warning', autoHideDuration: 10000 })
        else {
            enqueueSnackbar(`Un lien pour réinitialiser votre mot de passe vient de vous être envoyé sur votre téléphone mobile ${addressMobile}`, { variant: 'success', autoHideDuration: 10000 })
            setViewModeStep('connectStep1')
        }
        setLoader(false)
    }
    const handleForgotUserPasswordRecoverByAddress = async (event) => {
        setLoader(true)
        let pfUrl = config.pf.public.url + '/pf/public/user/userForgotPasswordByAddress';
        let payload = { addressMobile, userLogin }
        let responseKeepapi = await restIO({ endpoint: pfUrl, method: "post", jwt: false, payload });
        let { datas, extraData, details, code, status, error, showBackendMessage = false, message: backendMessage } = responseKeepapi
        status = parseInt(status)
        if (showBackendMessage === true) enqueueSnackbar(backendMessage, { variant: 'warning', autoHideDuration: 10000 })
        else if (code !== 'ok') enqueueSnackbar("Erreur lors de la recherche de votre adresse postale", { variant: 'warning', autoHideDuration: 10000 })
        else {
            enqueueSnackbar("Un lien pour réinitialiser votre mot de passe vient de vous être envoyé sur votre adresse postale.", { variant: 'success', autoHideDuration: 10000 })
            setViewModeStep('connectStep1')
        }
        setLoader(false)
    }

    const handleForgotUserLogin = async (event) => {
        setLoader(true)
        let pfUrl = config.pf.public.url + '/pf/public/user/userForgotUserLogin';
        let payload = {
            userForgotUserLoginAgentLastName: agentLastName
            , userForgotUserLoginAgentFirstName: agentFirstName
            , userForgotUserLoginAddressPostcode: addressPostcode
            , userForgotUserLoginUserEmail: userEmail
            , userForgotUserLoginAddressMobile: addressMobile
            , typingCodeUserForgotUserLogin: viewModeStep === "typingCodeUserForgotUserLoginAgent"
        }
        let responseKeepapi = await restIO({ endpoint: pfUrl, method: "post", jwt: false, payload });
        let { datas, extraData, details, code, status, error, showBackendMessage = false, message: backendMessage } = responseKeepapi
        status = parseInt(status)
        if (showBackendMessage === true) enqueueSnackbar(backendMessage, { variant: 'warning', autoHideDuration: 10000 })
        else if (status === 400) {
            for (let [key, value] of Object.entries(backendMessage)) {
                enqueueSnackbar(value.msg, { variant: "error", autoHideDuration: 10000 })
            }
        }
        else if (code !== 'ok') enqueueSnackbar("Nous sommes dans l'incapacité d'enregistrer votre demande. Veuillez réessayer à nouveau dans quelques secondes. Si le problème persiste merci de contacter le support support@keepapi.com", { variant: 'warning', autoHideDuration: 30000 })
        else {
            enqueueSnackbar("Votre demande a bien été prise en compte par notre équipe. Une validation va être faite et nous vous transmettrons sous 48H un message avec votre identifiant et un lien pour réinitialiser votre mot de passe.", { variant: 'success', autoHideDuration: 30000 })
            setViewModeStep('connectStep1')
        }
        setLoader(false)
    }
    const handleLogIn = async (event) => {

        setLoader(true)
        try {
            if (event) event.preventDefault()
            if (userPassword.trim() === "") {
                let message = "Le mot de passe est vide"
                enqueueSnackbar(message, { variant: 'warning' })
                setLoader(false)
                return
            }

            // if (!userId) {
            //     let message = `Nous n'avons pas trouvé de correspondance dans notre base de données. Veuillez soit contacter l'administrateur si le problème persiste ou utiliser le lien "mot de passe oublié"`
            //     enqueueSnackbar(message, { variant: 'warning' })
            //     setLoader(false)
            //     return
            // }
            removeServiceWorker() // remove service worker cache
            deleteAllCookies([config.app.kmSessionName]) // remove cookies
            window.localStorage.removeItem('JWT') // remove JWT
            let oauthLoginUrl = config.oauth.keepapiLoginLocal.url
            let responseKeepapi = await restIO({ method: 'post', endpoint: oauthLoginUrl, payload: { userLogin, userPassword } }) // fecth oauth login url
            // at this time cookie are stored in browser
            let { datas, extraData, details, code, error } = responseKeepapi

            let loggedIn = false
            if (code === 'ok') {
                let data = datas[0]
                if (data.auth === true && data.token !== '') { // you are logged !!!
                    window.localStorage.setItem('JWT', data.token)
                    window.localStorage.setItem('refresh', data.refresh)
                    loggedIn = true
                }
            }
            if (loggedIn === true) {
                // prepare autolog url for admin
                let autologUrl = config.admin.autolog.url
                autologUrl = autologUrl.replace('___TOKEN___', window.localStorage.getItem('JWT'))
                window.location.href = autologUrl // let's go to autolog at admin
            }
            else if (userDeleteDatetime !== "" && datas.message && datas.message === '') {
                let message = `Vous avez été sorti des utilisateurs. Vous ne pouvez plus vous connecter à KeepALink.`
                enqueueSnackbar(message, { variant: 'info' })
            }
            else {
                let message = datas.message
                enqueueSnackbar(message, { variant: 'warning' })
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
        }
    }
    const handleViewForgotUserLogin = () => {
        setViewModeStep("forgotUserLogin")

    }
    const handleForgotPassword = () => { }
    return (
        <div className={classes.card}>
            <div color="primary" className={classes.cardHeader}>
                <h4>
                    {viewModeStep === "connectStep1" && "Connectez-vous (étape 1/2)"}
                    {viewModeStep === "connectStep2" && "Connectez-vous (étape 2/2)"}
                    {viewModeStep === "connectStep3" && "Connexion automatique en cours"}
                    {viewModeStep === "forgotUserLogin" && <><b>Identifiant oublié.</b><br /> Veuillez remplir toutes les informations suivantes afin que nous validions que vous êtes bien détenteur du compte <br />(étape 1/2)</>}
                    {viewModeStep === "forgotUserLoginAgent" && <><b>Identifiant oublié.</b><br /> Veuillez remplir toutes les informations suivantes afin que nous validions que vous êtes bien détenteur du compte <br />(étape 2/2)</>}
                    {viewModeStep === "forgotUserPasswordRecoverByEmail" && <><b>Mot de passe oublié. (mode email)</b></>}
                    {viewModeStep === "forgotUserPasswordRecoverBySMS" && <><b>Mot de passe oublié. (mode SMS)</b></>}
                    {viewModeStep === "forgotUserPasswordRecoverByAddress" && <><b>Mot de passe oublié. (mode papier)</b>
                        <br /> Nous n'avons pas détecté d'adresse email, ni numéro de téléphone lié à votre compte. Nous pouvons vous transmettre par voie postale un lien de réinitialisation.
                    </>}
                    {viewModeStep === "forgotUserPasswordNoSMSNoEmailNoAddressRecover" && <><b>Mot de passe oublié. Il semble que vous n'ayez ni SMS ni Email associé à votre compte</b></>}
                </h4>
            </div>
            <CardContent>
                <form margin="normal" onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                        ev.preventDefault()
                        if (viewModeStep === "connectStep1") handleUserLogin()
                        else if (viewModeStep === "connectStep2") handleLogIn()
                        else if (viewModeStep === "forgotUserPasswordRecoverByEmail") handleForgotUserPasswordRecoverByEmail()
                        else if (viewModeStep === "forgotUserPasswordRecoverBySMS") handleForgotUserPasswordRecoverBySMS()


                    }
                }}>
                    {(viewModeStep === "connectStep1" || viewModeStep === "connectStep2") &&
                        <TextField margin="normal" fullWidth autoFocus={viewModeStep === "connectStep1"}
                            id="loginPuppet"
                            InputProps={{ endAdornment: (<InputAdornment position="end">    <PersonRounded /></InputAdornment>) }}
                            label="Identifiant"
                            disabled={viewModeStep !== "connectStep1"}
                            value={userLogin}
                            onChange={(ev) => setUserLogin(ev.target.value.replaceAll(' ', ''))}>
                        </TextField>
                    }
                    {viewModeStep === "connectStep2" &&
                        <TextField margin="normal" fullWidth autoFocus={viewModeStep === "connectStep2"}
                            id="passwordPuppet"
                            InputProps={{
                                type: !!flagPasswordVisible === true ? 'text' : 'password',
                                endAdornment: (<InputAdornment position="end" onClick={() => setFlagPasswordVisible(!flagPasswordVisible)}>{!!flagPasswordVisible === true ? <LockRounded /> : <VisibilityRounded />}</InputAdornment>)
                            }}
                            label="Mot de passe"
                            value={userPassword}
                            onChange={(ev) => setUserPassword(ev.target.value)}>
                        </TextField>
                    }
                    {(viewModeStep === "forgotUserLoginAgent") &&
                        <TextField margin="normal" fullWidth InputProps={{ endAdornment: (<InputAdornment position="end">    <PersonRounded /></InputAdornment>) }}
                            label="Votre nom"
                            value={agentLastName}
                            onChange={(ev) => setAgentLastName(ev.target.value)}>
                        </TextField>
                    }
                    {(viewModeStep === "forgotUserLoginAgent") &&
                        <TextField margin="normal" fullWidth InputProps={{ endAdornment: (<InputAdornment position="end">    <PersonRounded /></InputAdornment>) }}
                            label="Votre prénom"
                            value={agentFirstName}
                            onChange={(ev) => setAgentFirstName(ev.target.value)}>
                        </TextField>
                    }
                    {(viewModeStep === "forgotUserLoginAgent") &&
                        <TextField margin="normal" fullWidth InputProps={{ endAdornment: (<InputAdornment position="end">    <HomeRounded /></InputAdornment>) }}
                            label="Code postal de votre adresse"
                            value={addressPostcode}
                            onChange={(ev) => setAddressPostcode(ev.target.value)}>
                        </TextField>
                    }
                    {(viewModeStep === "forgotUserLoginAgent" || (viewModeStep === "forgotUserPasswordRecoverByEmail" && !!flagHasEmail === true)) &&
                        <TextField margin="normal" fullWidth InputProps={{ endAdornment: (<InputAdornment position="end">    <MailRounded /></InputAdornment>) }}
                            label="Votre adresse mail"
                            value={userEmail}
                            error={userEmail !== '' && !!flagErrorUserEmail}
                            helperText={userEmail !== '' && !!flagErrorUserEmail === true && "L'email saisi n'est pas valide"}
                            onChange={(ev) => setUserEmail(ev.target.value)}>
                        </TextField>
                    }
                    {(viewModeStep === "forgotUserLoginAgent" || (viewModeStep === "forgotUserPasswordRecoverBySMS" && !!flagHasSMS === true)) &&
                        <TextField margin="normal" fullWidth InputProps={{ endAdornment: (<InputAdornment position="end">    <PhoneRounded /></InputAdornment>) }}
                            label="Votre numéro de téléphone mobile"
                            value={addressMobile}
                            error={addressMobile !== '' && !!flagErrorAddressMobile}
                            helperText={addressMobile !== '' && !!flagErrorAddressMobile === true && "Votre numéro de portable saisi n'est pas valide"}
                            onChange={(ev) => setAddressMobile(ev.target.value)}>
                        </TextField>
                    }
                    {!!config.app.debugMode === true && <Button onClick={() => {
                        console.log(nirGenerator.generateNir())
                    }}>getNir</Button>}
                    {(viewModeStep === "connectStep3") &&
                        <Grid container justifyContent={"center"}>
                            <Typography>
                                Veuillez patienter, vous allez être redirigé
                            </Typography>
                        </Grid>
                    }
                </form>
            </CardContent>
            <CardActions className={classes.cardActions}>
                {(viewModeStep === "forgotUserPasswordRecoverByEmail" && !!flagHasEmail === true) &&
                    <>
                        <Stack direction="row" spacing="4">
                            <Button color="primary" variant="text" onClick={() => setViewModeStep("connectStep2")}  >Précédent</Button>
                            <LoadingButton loading={loader} color="primary" variant="contained" disabled={flagErrorUserEmail} onClick={() => handleForgotUserPasswordRecoverByEmail()}  >Valider</LoadingButton>
                        </Stack>
                    </>
                }
                {(viewModeStep === "forgotUserPasswordRecoverBySMS" && !!flagHasSMS === true) &&
                    <>
                        <Stack direction="row" spacing="4">
                            <Button color="primary" variant="text" onClick={() => setViewModeStep("connectStep2")}  >Précédent</Button>
                            <LoadingButton loading={loader} color="primary" variant="contained" disabled={flagErrorAddressMobile} onClick={() => handleForgotUserPasswordRecoverBySMS()}  >Valider</LoadingButton>
                        </Stack>
                    </>
                }
                {(viewModeStep === "forgotUserPasswordRecoverByAddress" && !!flagHasAddress === true) &&
                    <>
                        <Stack direction="row" spacing="4">
                            <Button color="primary" variant="text" onClick={() => setViewModeStep("connectStep2")}  >Précédent</Button>
                            <LoadingButton loading={loader} color="primary" variant="contained" onClick={() => handleForgotUserPasswordRecoverByAddress()}  >Valider</LoadingButton>
                        </Stack>
                    </>
                }
                {viewModeStep === "connectStep1" &&
                    <>
                        <Stack direction="row" spacing="4">
                            <Button color="primary" variant="text" onClick={handleViewForgotUserLogin}  >Identifiant oublié ?</Button>
                            <Button id="nextAction" color="primary" variant="contained" onClick={handleUserLogin}   >Suivant</Button>
                        </Stack>
                    </>
                }
                {viewModeStep === "connectStep2" &&
                    <Stack direction="row">
                        <Button color="primary" variant="text" onClick={() => setViewModeStep("connectStep1")}  >Précédent</Button>
                        <LoadingButton id="connection" loading={loader} color="primary" variant="contained" onClick={handleLogIn}  >Connexion</LoadingButton>
                    </Stack>
                }
                {viewModeStep === "forgotUserLogin" &&
                    <Stack direction="row">
                        <Button color="primary" variant="text" onClick={() => setViewModeStep("connectStep1")}  >Précédent</Button>
                        <Button color="primary" variant="contained" onClick={() => setViewModeStep("forgotUserLoginAgent")}  >Je suis un agent</Button>
                    </Stack>
                }
                {viewModeStep === "forgotUserLoginAgent" &&
                    <Stack direction="row">
                        <Button color="primary" variant="text" onClick={() => setViewModeStep("forgotUserLogin")}  >Précédent</Button>
                        <LoadingButton loading={loader} color="primary" variant="contained" onClick={() => handleForgotUserLogin()}  >Valider</LoadingButton>
                    </Stack>
                }
            </CardActions>
            {viewModeStep === "connectStep2" && <Button color="inherit" variant="text" onClick={() => handleForgotUserPassword()}  >Mot de passe oublié</Button>}
            {(viewModeStep === "forgotUserPasswordRecoverByEmail" && !!flagHasSMS === true) && <Button color="inherit" variant="text" onClick={() => setViewModeStep("forgotUserPasswordRecoverBySMS")} startIcon={<PhoneRounded />} >Essayer avec mon téléphone mobile</Button>}
            {(viewModeStep === "forgotUserPasswordRecoverBySMS" && !!flagHasEmail === true) && <Button color="inherit" variant="text" onClick={() => setViewModeStep("forgotUserPasswordRecoverByEmail")} startIcon={<MailRounded />} >Essayer avec mon email</Button>}
            {(viewModeStep === "forgotUserPasswordRecoverByEmail" || viewModeStep === "forgotUserPasswordRecoverBySMS") &&
                <>
                    <Divider className={classes.divider}>ou aucun identifiant</Divider>
                    <Grid spacing="8" container direction="column" justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <Button color="inherit" variant="text" onClick={() => setViewModeStep("forgotUserLogin")}  >Identifiants oubliés</Button>
                        </Grid>
                    </Grid>
                </>
            }
            {viewModeStep === "connectStep2" && !!flagHasEmail === true && !!flagCanMagickLink === true &&
                <>
                    <Divider className={classes.divider}>ou</Divider>
                    <Grid spacing="8" container direction="column" justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <Typography variant="h4">Envoi d'un lien à usage unique</Typography>
                        </Grid>
                        <Grid item xs={12} spacing="2">
                            <Typography variant="body2">Nous pouvons vous envoyer par e-mail un lien à <br />usage unique qui vous permettra de vous <br />connecter sans avoir à saisir votre mot de passe.</Typography>
                        </Grid>
                    </Grid>
                    <CardActions className={classes.cardActions}>
                        {viewModeStep === "connectStep2" && <LoadingButton loading={loader} color="primary" variant="contained" startIcon={<AutoFixHighRounded />} onClick={handleMagickLink}>Recevoir un lien par e-mail</LoadingButton>}
                    </CardActions>
                </>
            }

            {/* {viewModeStep === "connectStep2" && !!flagHasEmail === true && emailProviderWithAvailableSubscription === "gmail.com" &&
                <>
                    <Divider className={classes.divider}>ou utilisez votre adresse mail</Divider>
                    <CardActions className={classes.cardActions}>
                        <GoogleButton2022
                            saleId={config.socialNetwork.google.saleId}
                            fullWidth
                            text="Connexion avec Google"
                            onClick={handleSubscribeWithProvider}
                        />
                    </CardActions>
                </>
            }
            {viewModeStep === "connectStep2" && !!flagHasEmail === true && emailProviderAlreadySubscribe === "gmail.com" &&
                <>
                    <Divider className={classes.divider}>Se connecter avec votre adresse mail</Divider>
                    <CardActions className={classes.cardActions}>
                        <GoogleButton2022
                            saleId={config.socialNetwork.google.saleId}
                            fullWidth
                            text="Connexion avec Google"
                            onClick={handleLogInWithGoogle}
                        />
                    </CardActions>
                </>
            } */}

        </div >
    )
}

export default LoginPage
