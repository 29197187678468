// const gitInfo = process.env.NODE_ENV !== 'production' ? GitInfo() : {}
const config = {
	maintenance: process.env.REACT_APP_MAINTENANCE || false,
	app: {
		serviceName: `${process.env.REACT_APP_ENV}-${process.env.REACT_APP_MAIN_FOLDER_NAME}.${process.env.REACT_APP_MAIN_DOMAIN_NAME}`,
		adminUrlPublic:  `https://${process.env.REACT_APP_ENV}-${process.env.REACT_APP_MAIN_FOLDER_NAME}.${process.env.REACT_APP_MAIN_DOMAIN_NAME}`,
		apiUrlPublic: `https://${process.env.REACT_APP_ENV}-api.${process.env.REACT_APP_MAIN_DOMAIN_NAME}`,
		debugMode: process.env.REACT_APP_ENV === "local" && (process.env.REACT_APP_DEBUG_MODE || window.localStorage.getItem('debugMode') === "true" || false)
		, developerSourceCodeServerPath: process.env.REACT_APP_DEVELOPER_SOURCE_CODE_SERVER_PATH
		, codeSourceIdePath: process.env.REACT_APP_CODE_SOURCE_IDE_PATH
		, kmSessionName : `${process.env.REACT_APP_ENV}-${process.env.REACT_APP_MAIN_DOMAIN_NAME}`
	},
	domainName: process.env.REACT_APP_MAIN_DOMAIN_NAME,
	securityPolicy: {
		saltRound: 10,
		patternUserPassword: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-.'"+~:€£{[|\\^\]}%!?@#$%^&*;,_°])(?=.{8,})*[^\s]+$/,
		patternUserPasswordText: `Le mot de passe doit comporter au moins 8 caractères dont un chiffre, une minuscule, une majuscule, et au moins un caractère spécial de type -+~:€£{[|]}%!?@#$%^&*;,_°"}`,
		patternUserConfirmPasswordText: `Les mots de passes doivent être identiques.`
	},
	socialNetwork: {
		google: {
			saleId: "207934227074-b4ju481ob8fcijjo4al7sv4tcej6mae5.apps.googleusercontent.com"
		}
	},
	front: {
		home: {
			url: `https://${process.env.REACT_APP_ENV}-front.${process.env.REACT_APP_MAIN_DOMAIN_NAME}`
		},
	},
	admin: {
		home: {
			url: `https://${process.env.REACT_APP_ENV}-admin.${process.env.REACT_APP_MAIN_DOMAIN_NAME}`
		},
		autolog: {
			url: `https://${process.env.REACT_APP_ENV}-admin.${process.env.REACT_APP_MAIN_DOMAIN_NAME}/autolog/___TOKEN___`
		},
	},

	api: {
		public: {
			login: {
				// getTransactionalRouteUrl: {
				// 	url: `https://${process.env.REACT_APP_ENV}-api.${process.env.REACT_APP_MAIN_DOMAIN_NAME}/api/public/login/getTransactionalRoute`
				// },
				magickLinkCommunication: {
					url: `https://${process.env.REACT_APP_ENV}-api.${process.env.REACT_APP_MAIN_DOMAIN_NAME}/api/public/login/magickLinkCommunication`
				}
			},
			maintenance: {
				url: `https://${process.env.REACT_APP_ENV}-api.${process.env.REACT_APP_MAIN_DOMAIN_NAME}/maintenance`
			}
		}
	},
	oauth: {
		probe: {
			url: `https://${process.env.REACT_APP_ENV}-oauth.${process.env.REACT_APP_MAIN_DOMAIN_NAME}/probe`
		},
		logout: {
			url: `https://${process.env.REACT_APP_ENV}-oauth.${process.env.REACT_APP_MAIN_DOMAIN_NAME}/oauth/logout`
		},
		login: {
			url: `https://${process.env.REACT_APP_ENV}-oauth.${process.env.REACT_APP_MAIN_DOMAIN_NAME}/oauth/login`
		},
		keepapiLoginLocal: {
			url: `https://${process.env.REACT_APP_ENV}-oauth.${process.env.REACT_APP_MAIN_DOMAIN_NAME}/oauth/keepapiLoginLocal`
		},
		
		loginWithMagickLink: {
			url: `https://${process.env.REACT_APP_ENV}-oauth.${process.env.REACT_APP_MAIN_DOMAIN_NAME}/oauth/loginWithMagickLink`
		},
		logFromClient: {
			url: `https://${process.env.REACT_APP_ENV}-oauth.${process.env.REACT_APP_MAIN_DOMAIN_NAME}/oauth/logFromClient`
		},
		loginWithGoogle: {
			url: `https://${process.env.REACT_APP_ENV}-oauth.${process.env.REACT_APP_MAIN_DOMAIN_NAME}/oauth/loginWithGoogle`
		},
		register: {
			url: `https://${process.env.REACT_APP_ENV}-oauth.${process.env.REACT_APP_MAIN_DOMAIN_NAME}/oauth/register`
		},
		isAuthenticated: {
			url: `https://${process.env.REACT_APP_ENV}-oauth.${process.env.REACT_APP_MAIN_DOMAIN_NAME}/oauth/isAuthenticated`
		}
	},
	pf: {
		public: {
			url: `https://${process.env.REACT_APP_ENV}-pf.${process.env.REACT_APP_MAIN_DOMAIN_NAME}`,
			user: {
				changePassword: {
					url: `https://${process.env.REACT_APP_ENV}-pf.${process.env.REACT_APP_MAIN_DOMAIN_NAME}/pf/public/user/changePassword`,
				}
			},
		},
	}
};

export default config;
