import React, { createContext, Component, lazy } from 'react'
import { restIO } from 'utils/io'
import config from 'config.js'
import { withSnackbar } from 'notistack'
import { Grid, Zoom } from '@mui/material';
import { KTooltip } from 'components/KComponents/KTooltip';
import UseDynamicIcon from '../utils/UseDynamicIcon';
import { HelpOutlineRounded } from '@mui/icons-material';

export const KMInfraContext = createContext({
  infraStore: {},
  infraStoreRightPermissionDenied: true,
  getTypingIdBy: () => { },
  getTypingBy: () => { },
  getTypingsBy: () => { },
  formatTyping: () => { }
})
class KMInfraProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      infraStore: {},
      infraStoreRightPermissionDenied: true,
      getTypingBy: this.getTypingBy,
      formatTyping: this.formatTyping,
      getTypingsBy: this.getTypingsBy,
    }
  }
  componentDidMount() {
    this.initInfra()
  }
  getTypingIdBy = ({ typingCode = "" } = {}) => {
    if (typingCode !== "") {
      let needles = this.state.infraStore.typings.filter(typing => typing.typingCode === typingCode)
      if (needles.length === 1) return needles[0].typingId
    }
    return 0
  }
  getTypingsBy = ({ typingTargetColumn = "" } = {}) => {
    if (typingTargetColumn !== "") {
      return this.state.infraStore.typings.filter(typing => typing.typingTargetColumn === typingTargetColumn)
    }
    return []
  }
  getTypingBy = ({ typingId = 0, typingCode = "" } = {}) => {
    if (typingId !== 0) {
      let needles = this.state.infraStore.typings.filter(typing => typing.typingId === typingId)
      if (needles.length === 1) return needles[0]
    } else if (typingCode !== "") {
      let needles = this.state.infraStore.typings.filter(typing => typing.typingCode === typingCode)
      if (needles.length === 1) return needles[0]
    }
    return {}
  }
  formatTyping = ({ typingId = 0, typing = null, flagShowLabel = true } = {}) => {
    if (!typing && typingId > 0) typing = this.getTypingBy({ typingId })
    let { typingMaterialIcons = [], typingMaterialIconColor, typingLabel, typingDescription } = typing
    return (
      <KTooltip TransitionComponent={Zoom} arrow title={typingDescription} >
        <Grid container direction="row" alignItems="center">
          {typingMaterialIcons.map(typingMaterialIcon =>
            <Grid item>{typingMaterialIcon && <UseDynamicIcon iconName={typingMaterialIcon} style={{ color: typingMaterialIconColor }} fontSize="small" />}</Grid>
          )}
          {!!flagShowLabel === true && <Grid item>{typingLabel}</Grid>}
        </Grid>
      </KTooltip>
    )
  }
  async initInfra() {
    let { enqueueSnackbar } = this.props
    let endpoint = config.api.public.infra.react.url
    let responseKeepapi = await restIO({ endpoint, jwt: false })
    let { datas, code } = responseKeepapi
    if (code !== 'ok') {
      return enqueueSnackbar(`Votre demande ne peux aboutir. 202409091738`, { variant: 'warning', })
    }
    if (datas.length > 0) {
      datas[0].typings = datas[0].typings.map(typing => {
        let { typingMaterialIcons } = typing
        try {
          typing.typingMaterialIcons = JSON.parse(typingMaterialIcons)
        } catch (error) {
          typing.typingMaterialIcons = [typingMaterialIcons]
        }
        return typing
      })
      datas[0].themeJSON = JSON.parse(datas[0].themeJSON)

      let infraStore = datas[0]
      this.setState({ infraStore, infraStoreRightPermissionDenied: false })
      return true
    }
    return false
  }
  render() {
    return (
      <KMInfraContext.Provider value={this.state}>
        {this.props.children}
      </KMInfraContext.Provider>
    )
  }
}
export default withSnackbar(KMInfraProvider)

export const withInfraStore = Component => props => (
  <KMInfraContext.Consumer>
    {store => {
      return (<Component {...props} {...store} />)
    }
    }
  </KMInfraContext.Consumer>
)