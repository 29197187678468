import { useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import queryString from 'query-string';

const CTAKoPage = (props) => {
    const history = useHistory()
    const { ...location } = useLocation()
    let search = queryString.parse(location.search) || '';
    // debugger
    useEffect(() => {
        setTimeout(() => history.push('/logout'), 5000)
    }, [])
    return (
        <>
            {(search && search.message) ? search.message : "le lien a expiré"}
        </>
    )
}

export default CTAKoPage